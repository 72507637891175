.challenges-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #282727;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.challenges-container {
  flex-direction: column;
  height: 600px;
  width: 600px;
  background-color: #edeed1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
  left: 10px;
}

.challenges-container::-webkit-scrollbar {
  width: 5px;
}

.challenges-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.challenges-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}

.challenges-ul {
  list-style: none;
}
.challenges-li {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  padding: 20px;
}

.challenges-button {
  font-weight: bold;
  margin: 10px;
  align-self: center;
  background-color: #e1e2bf;
  font-family: 'Roboto', sans-serif;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .45rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.challenges-button:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.challenges-button:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

.challenges-h2 {
  position: absolute;
  top: 100px;
  font-weight: bold;
  color: #000;
  font-family: 'Roboto', sans-serif;
}

.challenges-div {
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  top: 20px;
}

.challenges-div::-webkit-scrollbar {
  width: 5px;
}

.challenges-div::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.challenges-div {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}
