.auth-container {
  background-color: #282727;
  height: 100vh;
}

.auth-h1 {
  text-align: center;
  padding-top: 50px;
  color: #ccc;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.auth-ul {
  list-style-type: none;
  padding-inline-start: 0;
  color: red;
}

.auth-label {
  margin-bottom: 10px;
}

.auth-input {
  display: block;
  margin-top: 5px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.auth-button {
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  transition-duration: 0.4s;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.auth-button:hover {
  background-color: #a7e5a9;
  color: black;
}

.auth-button-demo {
  cursor: pointer;
  background-color: #3d9ad8;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  transition-duration: 0.4s;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.auth-button-demo:hover {
  background-color: #89b8d7;
  color: black;
}
