.chat-container {
  overflow-y: auto;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  order: 1;
  margin-top: 250px;
  max-width: 300px;
  width: 300px;
  height: 260px;
  background-color: #fff;
  border-radius: 20px;
  z-index: 5000;
}
.chat-box {
  cursor: grab;
  overflow-y: auto;
  border: 1px solid #ccc;
  width: 100%;
  height: 200px;
  background-color: #f0eeee;
  z-index: 2000;
}

.chat-handle {
  cursor: grab;
  padding: 5px;
  background-color: #ccc;
  text-align: center;
  border-radius: 10px 10px 0 0;
}

.chat-message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.chat-username {
  margin-right: 10px;
}

.chat-button {
  background-color: #afbfc9;
  border: 1px solid #000;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
  margin-left: 8px;
}

.chat-form {
  margin-top: 20px;
  margin-left: 5px;
}

.chat-input.invalid {
  color: red;
}

.chat-box::-webkit-scrollbar {
  width: 4px;
}

.chat-box::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.chat-box {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}
