.profile-dropdown {
  position: relative;
  top: 30px;
  left: 0;
  background: white;
  width: 300px;
  z-index: 1;
  height: fit-content;
  overflow-y: auto;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.profile-icon {
  padding: 5px;
  border-radius: 5px;
  background-color: #e1e2bf;
  cursor: pointer;
  margin-top: 30px;
}


.profile-li {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  border-bottom: 1px solid #000;
}

.profile-logout {
  margin-bottom: 25px;
  background-color: #aaa8a8;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  transition: 200ms;
  width: 80px;
  box-sizing: border-box;
  border: 0;
  font-size: 12px;
  height: 30px;
}

.profile-logout:hover {
  background-color: #c8c8c8;
}

.fas.fa-user-circle {
  font-size: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.profile-link {
  font-size: 15px;
  text-decoration: none;
  color: #000;
}


.hidden {
  display: none;
}
.search-results {
  max-height: 100px;
  overflow-y: auto;
  background-color: #fff;
  max-width: 300px;
  border-radius: 5px;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
}

.navBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  /* background-color: #6d6666; */
  background-color: #282727;
  width: 400px;
  height: 90vh;
  position: fixed;
  z-index: 100000;
  right: 0;
}

.navBar-contents {
  position: relative;
  left: 50px;
  top: 50px;
}

.nav-link {
  padding-bottom: 10px;
  font-size: 20px;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
}

.nav-about {
  margin-top: 20px;
  position: relative;
  top: 8px;
}

.searchInput {
  border-radius: 5px;
  height: 30px;
  width: 250px;
  margin-top: 40px;
  margin-bottom: 10px;
  position: relative;
}

.open-nav-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  margin-right: 40px;
  margin-top: 40px;
  border-radius: 50px;
  padding: 10px 15px;
  background-color: #1c1f1c;
  color: #fff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  font-weight: bold;
  cursor: pointer;
}

.open-nav-button:hover {
  background-color: #191a19;
}

.toggle-nav-button {
  position: fixed;
  right: 380px;
  top: 20px;
  background-color: #282727;
  border: none;
  color: #fff;
  margin-bottom: 100px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 20px;
  font-family: cursive;
}

.search-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-title {
  margin-right: 10px;
}

.request-button {
  background-color: #41b166;
  border: 1px solid #000;
  padding: 2px 4px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
}

.request-button:hover {
  background-color: #3a9d5b;
}

.request-p {
  color: #10c932;
  font-size: 12px;
  font-weight: bolder;
  font-family: 'Roboto', sans-serif;
}
