.app-container {
  display: flex;
  justify-content: space-between;
  background-color: #282727;
}

.chessboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  padding-top: 40px;
  border: 1px solid #000;
  flex: 1;
  order: 2;
  overflow: auto;
}

.promotion-modal {
  position: absolute;
  top: 250px;
  right: 600px;
  bottom: 120px;
  left: 600px;
}

.hide-modal {
  display: none;
}

.promotion-modal .modal-body {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  width: 600px;
  top: calc(50% - 150px);
  left: calc(50% - 300px);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.promotion-modal .modal-body .promotion-image {
  height: 75px;
  width: 75px;
  transition: ease-in-out 0.2s;
}
.promotion-modal .modal-body .promotion-image:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 5px;
  transition: ease-in-out 0.2s;
}

.chessboard-h3-bottom {
  border: 3px solid rgb(79, 80, 79);
  width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
  right: 280px;
}

.chessboard-h3-top {
  border: 3px solid rgb(79, 80, 79);
  width: fit-content;
  padding: 5px 8px;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  right: 280px;
}

.highlight {
  border: 3px solid rgb(229, 240, 16);
  color: rgb(196, 206, 16);
}
