.about-container {
  width: 100vw;
  height: 100vh;
  background-color: #282727;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-square {
  height: 600px;
  width: 600px;
  background-color: #000;
  border-radius: 50px;
  z-index: 2000;
}


.about-img {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  margin-left: 20px;
}

.github-img {
  height: 90px;
  width: 90px;
  border-radius: 100px;
}

.linkedin-img {
  height: 45px;
  width: 45px;
}

.icon-container {
  width: 200px;
  position: relative;
  left: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 150px;
}


.about-info {
  /* border: 1px solid #fff; */
  height: 300px;
  width: 500px;
  position: relative;
  left: 50px;
  bottom: 50px;
}

.about-info-p {
  color: #fff;
  font-size: 15px;
  line-height: 1.8;
  padding: 0 10px;
  text-align: justify;
}

.chess-pieces-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

.chess-piece {
  position: absolute;
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: falling 6s linear infinite;
}


.piece1 {
  background-image: url('../../pieceimages/whitebishop.png');
  left: 9%;
  animation-delay: -4s;
}

.piece2 {
  background-image: url('../../pieceimages/whiterook.png');
  left: 19.5%;
  animation-delay: -2s;
}
.piece3 {
  background-image: url('../../pieceimages/whitequeen.png');
  left: 29%;
  animation-delay: -5s;
}
.piece4 {
  background-image: url('../../pieceimages/whitepawn.png');
  left: 48%;
  animation-delay: -2s;
}
.piece5 {
  background-image: url('../../pieceimages/whiteking.png');
  left: 67%;
  animation-delay: -1s;
}
.piece6 {
  background-image: url('../../pieceimages/whiterook.png');
  left: 77%;
  animation-delay: -3s;
}
.piece7 {
  background-image: url('../../pieceimages/whiteknight.png');
  left: 90%;
  animation-delay: -5s;
}

@keyframes falling {
  0% {
    top: -70px;
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    top: 100vh;
    transform: rotate(360deg);
  }
}
