* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.the-logo {
  height: 120px;
  width: 150px;
  position: fixed;
  cursor: pointer;
}
