.homepage-container {
  width: 100vw;
  height: 100vh;
  background-color: #282727;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-startmatch {
  height: 600px;
  width: 650px;
  background-color: #edeed1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  position: relative;
  left: 10px;
  z-index: 1000;
  padding-left: 50px;
  padding-right: 50px;
}

.homepage-span {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  font-family: 'Roboto', sans-serif;
}

.homepage-span-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100px;
  position: relative;
  left: 100px;
  margin-top: 85px;
}

.homepage-button-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-between;
  position: relative;
  right: 100px;
  margin-top: 75px;
}

.homepage-grader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1.7rem;
  font-weight: 600;
  color: #000;
  font-family: 'Roboto', sans-serif;
  position: relative;
  left: 239px;
}

.homepage-p {
  margin-bottom: 10px;
}


.homepage-logo {
  position: absolute;
  top: 0;
  left: 32%;
}

.logo-img {
  height: 200px;
  width: 238px;
}


.homepage-button {
  font-weight: bold;
  margin: 10px;
  align-self: center;
  background-color: #e1e2bf;
  font-family: 'Roboto', sans-serif;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .5rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.homepage-button:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.homepage-button:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

.chess-pieces-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

.chess-piece {
  position: absolute;
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: falling 6s linear infinite;
}

.piece1 {
  background-image: url('../../pieceimages/whitebishop.png');
  left: 9%;
  animation-delay: -4s;
}

.piece2 {
  background-image: url('../../pieceimages/whiterook.png');
  left: 19.5%;
  animation-delay: -2s;
}
.piece3 {
  background-image: url('../../pieceimages/whitequeen.png');
  left: 29%;
  animation-delay: -5s;
}
.piece4 {
  background-image: url('../../pieceimages/whitepawn.png');
  left: 48%;
  animation-delay: -2s;
}
.piece5 {
  background-image: url('../../pieceimages/whiteking.png');
  left: 67%;
  animation-delay: -1s;
}
.piece6 {
  background-image: url('../../pieceimages/whiterook.png');
  left: 77%;
  animation-delay: -3s;
}
.piece7 {
  background-image: url('../../pieceimages/whiteknight.png');
  left: 90%;
  animation-delay: -5s;
}

@keyframes falling {
  0% {
    top: -70px;
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    top: 100vh;
    transform: rotate(360deg);
  }
}
